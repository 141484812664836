import { useEffect, useRef, useState } from "react";
import { useUser } from "../../hooks";

export const LiveChat = (): null => {
  const { currentUser } = useUser();

  const [chatElement, setChatElement] = useState<Node | null>(null);

  const chatRef = useRef(process.env.REACT_APP_ENABLE_INTERCOM_CHAT);
  const isChatEnabled = chatRef.current === "true";

  const createChatInstance = (): void => {
    if (!chatElement) {
      const intercomChatScript = document.createElement("script");
      intercomChatScript.type = "text/javascript";
      intercomChatScript.src = "./intercom.js";
      intercomChatScript.async = true;
      document.body.appendChild(intercomChatScript);
      setChatElement(intercomChatScript);
    }
  };

  const destroyChatInstance = (chatElement: Node): void => {
    if (!!(window as any)?.Intercom) {
      (window as any).Intercom("shutdown");
      document.body.removeChild(chatElement);
    }
  };

  useEffect(() => {
    if (isChatEnabled) {
      createChatInstance();

      if (currentUser) {
        (window as any).intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          email: currentUser?.email || "",
          user_hash: currentUser?.chat_hash || "",
          role: currentUser?.role || "member",
          plan: currentUser?.plan,
          paid_user: currentUser?.paid_user,
          teammates: currentUser?.teammates || 0,
          teammates_paid: currentUser?.teammates_paid || 0,
          domain: currentUser?.domain || "",
          available_ai_requests:
            (currentUser?.license?.given_ai_requests || 0) -
            (currentUser?.license?.used_ai_requests || 0),
          license_assigned: currentUser?.paid_user || "",
          license_number: currentUser?.license?.id || "",
          promocode: currentUser?.promocode?.code || "",
          promocode_start_date: currentUser?.promocode?.expire_at || "",
          promocode_duration: currentUser?.promocode?.expire_at || "",
          trial_date_start: currentUser?.trial_date_start || "",
          trial_date_end: currentUser?.trial_date_end || "",
          plan_start_date: currentUser?.plan_start_date || "",
          plan_expire_date: currentUser?.plan_expire_date || "",
        };
      } else {
        (window as any).intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        };
      }
    } else {
      chatElement && destroyChatInstance(chatElement);
    }
  }, [currentUser]);

  return null;
};
