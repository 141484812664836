import React, { FocusEvent, useState, useEffect, useRef } from "react";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  CredentialResponse,
} from "@react-oauth/google";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink, useNavigate } from "react-router-dom";
import { usersApi } from "../../api";
import { appConfig } from "../../app-config";
import { configuration } from "../../config";
import {
  APP_ROUTES,
  ERROR_MESSAGES,
  IAuthResponse,
  IRegisterParams,
  IResponseError,
  ModalType,
  SUCCESS_MESSAGES,
  VALID_EMAIL_REGEX,
  VALIDATION_MESSAGES,
} from "../../data-access";
import { useWindowDimensions } from "../../hooks";
import { FormInput, FormButton, BrandLogo } from "../../ui";
import {
  cookie,
  encryption,
  gtag,
  handleFailedRequest,
  notify,
  sanitizeEmail,
  updateExtensionCookies,
} from "../../util";
import "./registration.scss";
import { useDispatch } from "react-redux";
import { storeAuthActions } from "../../store/slices/auth";
import { storeBillingActions } from "../../store/slices/billing";
import { storeModalsActions } from "../../store/slices/modals";

interface IGoogleAuthInfo {
  name: string;
  email: string;
  picture: string;
  jti: string;
}

const validationScheme = yup
  .object({
    email: yup
      .string()
      .required()
      .matches(VALID_EMAIL_REGEX, VALIDATION_MESSAGES.INVALID_EMAIL),
  })
  .required();

export const Registration = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useForm<IRegisterParams>({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(validationScheme),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, dirtyFields },
  } = form;

  const maxGoogleButtonWidth = 380;

  const [isRequestProcessing, setIsRequestProcessing] =
    useState<boolean>(false);
  const [googleButtonWidth, setGoogleButtonWidth] =
    useState<number>(maxGoogleButtonWidth);

  const { width: screenWidth } = useWindowDimensions();

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      if (contentRef.current.clientWidth < maxGoogleButtonWidth) {
        setGoogleButtonWidth(contentRef.current.clientWidth);
      } else {
        setGoogleButtonWidth(maxGoogleButtonWidth);
      }
    }
  }, [screenWidth]);

  const handleFormSubmit = (formFields: IRegisterParams): void => {
    setIsRequestProcessing(true);
    usersApi
      .register(formFields)
      .then(() => {
        notify("success", SUCCESS_MESSAGES.SIGN_UP);
        gtag("config", "AW-11007670916");
        window.gtag("event", "registration", {
          event_category: "INSTAGRAM_ADS",
          event_label: "User has been registered",
        });
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsRequestProcessing(false);
        reset();
      });
  };

  const handleGoogleFormSubmit = (formFields: CredentialResponse): void => {
    setIsRequestProcessing(true);

    usersApi
      .googleAuthenticate(formFields.credential)
      .then((response: IAuthResponse) => {
        const authDetails = {
          access_token: response.access_token,
          refresh_token: response.refresh_token,
          user: response.user,
        };

        dispatch(storeAuthActions.setUserData(authDetails));

        if (response.user.promocode) {
          dispatch(
            storeBillingActions.update({ discount: response.user.promocode })
          );
        }

        cookie.set("exSM", encryption.generateHash("authorized"));
        updateExtensionCookies(authDetails);
        navigate(APP_ROUTES.dashboard);
        dispatch(
          storeModalsActions.open({
            type: ModalType.Welcome,
          })
        );
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsRequestProcessing(false));
  };

  const handleEmailInput = (e: FocusEvent<HTMLInputElement>): void => {
    const sanitizedEmail = sanitizeEmail(e?.target?.value);
    setValue("email", sanitizedEmail, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const isFormValid =
    Object.keys(dirtyFields).length > 0 && !errors?.email?.message;

  return (
    <div className="registration">
      <BrandLogo />
      <div ref={contentRef} className="registration__content">
        <h1 className="registration__title">Let's get started</h1>

        <h2 className="registration__description">
          Register to start your {appConfig.trialPeriodDuration}-days free trial
        </h2>
        <div
          className={`google-provider ${
            isRequestProcessing ? "google-provider--disabled" : ""
          }`}
        >
          <GoogleOAuthProvider
            clientId={`${process.env.REACT_APP_AUTH_CLIENT_ID}`}
          >
            <GoogleLogin
              size="large"
              context="signup"
              locale={"en"}
              width={`${googleButtonWidth}px`}
              onSuccess={(response: CredentialResponse) => {
                handleGoogleFormSubmit(response);
              }}
              onError={() => {
                notify("error", ERROR_MESSAGES.GENERIC);
              }}
            />
          </GoogleOAuthProvider>
        </div>
        <div className="registration__delimiter">
          <span>or sign up with email</span>
        </div>
        <form
          className="registration__form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Controller
            control={control}
            name="email"
            render={({ field: { name, value, ref } }) => (
              <FormInput
                ref={ref}
                name={name}
                value={value}
                placeholder="Enter your email address"
                error={errors?.email?.message}
                disabled={isRequestProcessing}
                onChange={handleEmailInput}
              />
            )}
          />
          <FormButton
            className="registration__submit-button"
            htmlType="submit"
            loading={isRequestProcessing}
            disabled={!isFormValid || isRequestProcessing}
          >
            Register
          </FormButton>
          <p className="registration__terms-and-conditions">
            By signing up, I agree with Upwex's{" "}
            <a href={configuration.termsLink} target="_blank" rel="noreferrer">
              Terms
            </a>{" "}
            &{" "}
            <a
              href={configuration.privacyPolicyLink}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </form>
      </div>
      <span className="registration__link">
        Already have an account?&nbsp;
        <NavLink to={APP_ROUTES.login}>Sign in here</NavLink>
      </span>
    </div>
  );
};
